const config = {
	baseUrl: "http://192.168.1.97:8000",
	blogUrl: "https://dailyhotels.id/wp-json/wp/v2",
	primaryColor: "#CA0C64",
	colors: {
		primary: "#CA0C64",
		green: "#2ecc71",
		grey: "#ddd",
		red: "#e74c3c",
		redDark: "#c0392b",
		yellow: "#fcd840",
		text: "#193C58",
		purple: "#8e24aa",
		cyan: "#00bcd4",
		orange: "#f57c00",
	},
	orgTypeOptions: [
		{ value: "pariwisata", label: "pariwisata" },
		{ value: "daily activities", label: "daily activities" },
		{ value: "tour", label: "tour" },
	],
	interestEventOptions: [
		{ value: "pariwisata", label: "pariwisata" },
		{ value: "daily activities", label: "daily activities" },
		{ value: "tour", label: "tour" },
	],
	months: [
		"Januari",
		"Februari",
		"Maret",
		"April",
		"Mei",
		"Juni",
		"Juli",
		"Agustus",
		"September",
		"Oktober",
		"November",
		"Desember",
	],
	days: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jum'at", "Sabtu"],
	dayEnToInd: {
		Sun: "Minggu",
		Mon: "Senin",
		Tue: "Selasa",
		Wed: "Rabu",
		Thu: "Kamis",
		Fri: "Jum'at",
		Sat: "Sabtu",
	},
	dayIndToEn: {
		Minggu: "Sun",
		Senin: "Mon",
		Selasa: "Tue",
		Rabu: "Wed",
		Kamis: "Thu",
		"Jum'at": "Fri",
		Sabtu: "Sat",
	},
	cities: [
		{
			provinsi: "Aceh",
			kota: [
				"Banda Aceh",
				"Sabang",
				"Lhokseumawe",
				"Langsa",
				"Subulussalam",
				"Aceh Selatan",
				"Aceh Tenggara",
				"Aceh Timur",
				"Aceh Tengah",
				"Aceh Barat",
				"Aceh Besar",
				"Pidie",
				"Aceh Utara",
				"Simeulue",
				"Aceh Singkil",
				"Bireun",
				"Aceh Barat Daya",
				"Gayo Lues",
				"Aceh Jaya",
				"Nagan Raya",
				"Aceh Tamiang",
				"Bener Meriah",
				"Pidie Jaya",
			],
		},

		{
			provinsi: "Sumatera Utara",
			kota: [
				"Medan",
				"Pematang Siantar",
				"Sibolga",
				"Tanjung Balai",
				"Binjai",
				"Tebing Tinggi",
				"Padang Sidempuan",
				"Gunung Sitoli",
				"Serdang Bedagai",
				"Samosir ",
				"Humbang Hasundutan",
				"Pakpak Bharat",
				"Nias Selatan",
				"Mandailing Natal",
				"Toba Samosir",
				"Dairi",
				"Labuhan Batu",
				"Asahan",
				"Simalungun",
				"Deli Serdang",
				"Karo",
				"Langkat",
				"Nias",
				"Tapanuli Selatan",
				"Tapanuli Utara",
				"Tapanuli Tengah",
				"Batu Bara",
				"Padang Lawas Utara",
				"Padang Lawas",
				"Labuhanbatu Selatan",
				"Labuhanbatu Utara",
				"Nias Utara",
				"Nias Barat",
			],
		},

		{
			provinsi: "Sumatera Barat",
			kota: [
				"Padang",
				"Solok",
				"Sawhlunto",
				"Padang Panjang",
				"Bukittinggi",
				"Payakumbuh",
				"Pariaman",
				"Pasaman Barat",
				"Solok Selatan",
				"Dharmasraya",
				"Kepulauan Mentawai",
				"Pasaman",
				"Lima Puluh Kota",
				"Agam",
				"Padang Pariaman",
				"Tanah Datar",
				"Sijunjung",
				"Solok",
				"Pesisir Selatan",
			],
		},

		{
			provinsi: "Riau",
			kota: [
				"Pekan Baru",
				"Dumai",
				"Kepulauan Meranti",
				"Kuantan Singingi",
				"Siak",
				"Rokan Hilir",
				"Rokan Hulu",
				"Pelalawan",
				"Indragiri Hilir",
				"Bengkalis",
				"Indragiri Hulu",
				"Kampar",
			],
		},

		{
			provinsi: "Jambi",
			kota: [
				"Jambi",
				"Sungai Penuh",
				"Tebo",
				"Bungo",
				"Tanjung Jabung Timur",
				"Tanjung Jabung Barat",
				"Muaro Jambi",
				"Batanghari",
				"Sarolangun",
				"Merangin",
				"Kerinci",
			],
		},

		{
			provinsi: "Sumatera Selatan",
			kota: [
				"Palembang",
				"Pagar Alam",
				"Lubuk Linggau",
				"Prabumulih",
				"Musi Rawas Utara",
				"Penukal Abab Lematang Ilir",
				"Empat Lawang",
				"Ogan Ilir ",
				"Ogan Komering Ulu Selatan ",
				"Ogan Komering Ulu Timur ",
				"Banyuasin",
				"Musi Banyuasin",
				"Musi Rawas",
				"Lahat",
				"Muara Enim",
				"Ogan Komering Ilir",
				"Ogan Komering Ulu",
			],
		},

		{
			provinsi: "Bengkulu",
			kota: [
				"Bengkulu",
				"Bengkulu Tengah",
				"Kepahiang ",
				"Lebong",
				"Muko Muko",
				"Seluma",
				"Kaur",
				"Bengkulu Utara",
				"Rejang Lebong",
				"Bengkulu Selatan",
			],
		},

		{
			provinsi: "Lampung",
			kota: [
				"Bandar Lampung",
				"Metro",
				"Pesisir Barat",
				"Tulangbawang Barat",
				"Mesuji",
				"Pringsewu",
				"Pesawaran",
				"Way Kanan",
				"Lampung Timur",
				"Tanggamus",
				"Tulang Bawang",
				"Lampung Barat",
				"Lampung Utara",
				"Lampung Tengah",
				"Lampung Selatan",
			],
		},

		{
			provinsi: "Kepulauan Bangka Belitung",
			kota: [
				"Pangkal Pinang",
				"Belitung Timur",
				"Bangka Barat",
				"Bangka Tengah",
				"Bangka Selatan",
				"Belitung",
				"Bangka",
			],
		},

		{
			provinsi: "Kepulauan Riau",
			kota: [
				"Batam",
				"Tanjung Pinang",
				"Kepulauan Anambas",
				"Lingga ",
				"Natuna",
				"Karimun",
				"Bintan",
			],
		},

		{
			provinsi: "DKI Jakarta",
			kota: [
				"Jakarta Timur",
				"Jakarta Selatan",
				"Jakarta Barat",
				"Jakarta Utara",
				"Jakarta Pusat",
				"Kepulauan Seribu",
			],
		},

		{
			provinsi: "Jawa Barat",
			kota: [
				"Bandung",
				"Banjar",
				"Tasikmalaya",
				"Cimahi",
				"Depok",
				"Bekasi",
				"Cirebon",
				"Sukabumi",
				"Bogor",
				"Pangandaran",
				"Bandung Barat",
				"Bekasi",
				"Karawang",
				"Purwakarta",
				"Subang",
				"Indramayu",
				"Sumedang",
				"Majalengka",
				"Cirebon",
				"Kuningan",
				"Ciamis",
				"Tasikmalaya",
				"Garut",
				"Bandung",
				"Cianjur",
				"Sukabumi",
				"Bogor",
			],
		},

		{
			provinsi: "Jawa Tengah",
			kota: [
				"Semarang",
				"Tegal",
				"Pekalongan",
				"Salatiga",
				"Surakarta",
				"Solo",
				"Magelang",
				"Brebes",
				"Tegal",
				"Pemalang",
				"Pekalongan",
				"Batang",
				"Kendal",
				"Temanggung",
				"Semarang",
				"Demak",
				"Jepara",
				"Kudus",
				"Pati",
				"Rembang",
				"Blora",
				"Grobogan",
				"Sragen",
				"Karanganyar",
				"Wonogiri",
				"Sukoharjo",
				"Klaten",
				"Boyolali",
				"Magelang",
				"Wonosobo",
				"Purworejo",
				"Kebumen",
				"Banjarnegara",
				"Purbalingga",
				"Banyumas",
				"Cilacap",
			],
		},

		{
			provinsi: "DI Yogyakarta",
			kota: ["Yogyakarta", "Sleman", "Gunung Kidul", "Bantul", "Kulon Progo"],
		},

		{
			provinsi: "Jawa Timur",
			kota: [
				"Surabaya",
				"Batu",
				"Madiun",
				"Mojokerto",
				"Pasuruan",
				"Probolinggo",
				"Malang",
				"Blitar",
				"Kediri",
				"Sumenep",
				"Pamekasan",
				"Sampang",
				"Bangkalan",
				"Gresik",
				"Lamongan",
				"Tuban",
				"Bojonegoro",
				"Ngawi",
				"Magetan",
				"Madiun",
				"Nganjuk",
				"Jombang",
				"Mojokerto",
				"Sidoarjo",
				"Pasuruan",
				"Probolinggo",
				"Situbondo",
				"Bondowoso",
				"Banyuwangi",
				"Jember",
				"Lumajang",
				"Malang",
				"Kediri",
				"Blitar",
				"Tulungagung",
				"Trenggalek",
				"Ponorogo",
				"Pacitan",
			],
		},

		{
			provinsi: "Banten",
			kota: [
				"Serang",
				"Cilegon",
				"Tangerang",
				"Tangerang Selatan",
				"Serang",
				"Tangerang",
				"Lebak",
				"Pandeglang",
			],
		},

		{
			provinsi: "Bali",
			kota: [
				"Denpasar",
				"Buleleng",
				"Karangasem",
				"Bangli",
				"Klungkung",
				"Gianyar",
				"Badung",
				"Tabanan",
				"Jembrana",
			],
		},

		{
			provinsi: "Nusa Tenggara Barat",
			kota: [
				"Mataram",
				"Bima",
				"Lombok Utara",
				"Sumbawa Barat",
				"Bima",
				"Dompu",
				"Sumbawa ",
				"Lombok Timur",
				"Lombok Tengah",
				"Lombok Barat",
			],
		},

		{
			provinsi: "Nusa Tenggara Timur",
			kota: [
				"Kupang",
				"Malaka",
				"Sabu Raijua",
				"Manggarai Timur",
				"Sumba Barat Daya",
				"Sumba Tengah",
				"Nagekeo",
				"Manggarai Barat",
				"Rote Ndao",
				"Lembata",
				"Sumba Barat",
				"Sumba Timur",
				"Manggarai",
				"Ngada",
				"Ende",
				"Sikka",
				"Flores Timur",
				"Alor",
				"Belu",
				"Timor Tengah Utara",
				"Timor Tengah Selatan",
				"Kupang",
			],
		},
		{
			provinsi: "Kalimantan Barat",
			kota: [
				"Pontianak",
				"Singkawang",
				"Kubu Raya",
				"Kayong Utara",
				"Sekadau",
				"Melawi",
				"Landak",
				"Bengkayang",
				"Kapuas Hulu",
				"Sintang ",
				"Ketapang",
				"Sanggau ",
				"Mempawah",
				"Sambas",
			],
		},

		{
			provinsi: "Kalimantan Tengah",
			kota: [
				"Palangkaraya",
				"Barito Timur",
				"Murung Raya",
				"Pulang Pisau",
				"Gunung Mas",
				"Lamandau",
				"Sukamara",
				"Seruyan",
				"Katingan",
				"Barito Utara",
				"Barito Selatan",
				"Kapuas",
				"Kotawaringin Timur",
				"Kotawaringin Barat",
			],
		},

		{
			provinsi: "Kalimantan Selatan",
			kota: [
				"Banjarmasin",
				"Banjarbaru",
				"Balangan",
				"Tanah Bambu",
				"Tabalong",
				"Hulu Sungai Utara",
				"Hulu Sungai Tengah",
				"Hulu Sungai Selatan",
				"Tapin",
				"Barito Kuala",
				"Banjar",
				"Kotabaru",
				"Tanah Laut",
			],
		},

		{
			provinsi: "Kalimantan Timur",
			kota: [
				"Samarinda",
				"Bontang",
				"Balikpapan",
				"Mahakam Ulu",
				"Penajam Paser Utara",
				"Kutai Timur",
				"Kutai Barat",
				"Berau",
				"Kutai Kertanegara",
				"Paser",
			],
		},

		{
			provinsi: "Kalimantan Utara",
			kota: ["Tarakan", "Tana Tidung", "Nunukan", "Malinau", "Bulungan"],
		},

		{
			provinsi: "Sulawesi Utara",
			kota: [
				"Manado",
				"Tomohon",
				"Bitung",
				"Kotamobagu",
				"Bolaang Mangondow Selatan",
				"Bolaang Mangondow Timur",
				"Kepulauan Siau Tagulandang Biaro",
				"Bolaang Mangondow Utara",
				"Minahasa Tenggara",
				"Minahasa Utara",
				"Minahasa Selatan",
				"Kepulauan Talaud",
				"Kepulauan Sangihe",
				"Minahasa",
				"Bolaang Mangondow",
			],
		},

		{
			provinsi: "Sulawesi Tengah",
			kota: [
				"Palu",
				"Morowali Utara",
				"Banggai Laut",
				"Sigi",
				"Tojo Una-Una",
				"Parigi Moutong",
				"Banggai Kepulauan",
				"Morowali",
				"Buol",
				"Toli-Toli",
				"Donggala",
				"Poso",
				"Banggai",
			],
		},

		{
			provinsi: "Sulawesi Selatan",
			kota: [
				"Makasar",
				"Palopo",
				"Pare Pare",
				"Toraja Utara",
				"Luwu Timur",
				"Luwu Utara",
				"Tana Toraja",
				"Luwu",
				"Enrekang",
				"Pinrang",
				"Sidenreng Rappang",
				"Wajo",
				"Soppeng",
				"Barru",
				"Pangkajene Kepulauan",
				"Maros",
				"Bone",
				"Sinjai",
				"Gowa",
				"Takalar",
				"Jeneponto",
				"Bantaeng",
				"Bulukumba",
				"Kepulauan Selayar",
			],
		},

		{
			provinsi: "Sulawesi Tenggara",
			kota: [
				"Kendari",
				"Bau Bau",
				"Buton Selatan",
				"Buton Tengah",
				"Muna Barat",
				"Konawe Kepulauan",
				"Kolaka Timur",
				"Buton Utara",
				"Konawe Utara",
				"Kolaka Utara",
				"Wakatobi",
				"Bombana",
				"Konawe Selatan",
				"Buton",
				"Muna",
				"Konawe",
				"Kolaka",
			],
		},

		{
			provinsi: "Gorontalo",
			kota: [
				"Gorontalo",
				"Pohuwato",
				"Bone Bolango",
				"Boalemo",
				"Gorontalo",
				"Gorontalo Utara",
			],
		},

		{
			provinsi: "Sulawesi Barat",
			kota: [
				"Majene",
				"Polowali Mandar",
				"Mamasa",
				"Mamuju",
				"Mamuju Utara",
				"Mamuju Tengah",
			],
		},

		{
			provinsi: "Maluku",
			kota: [
				"Ambon",
				"Tual",
				"Buru Selatan",
				"Maluku Barat Daya",
				"Kepulauan Aru",
				"Seram Bagian Barat ",
				"Seram Bagian Timur",
				"Buru",
				"Maluku Tenggara Barat",
				"Maluku Tenggara",
				"Maluku Tengah",
			],
		},

		{
			provinsi: "Maluku Utara",
			kota: [
				"Ternate",
				"Tidore Kepulauan",
				"Pulau Taliabu",
				"Pulau Morotai",
				"Halmahera Timur",
				"Kepulauan Sula",
				"Halmahera Selatan",
				"Halmahera Utara",
				"Halmahera Tengah",
				"Halmahera Barat",
			],
		},

		{
			provinsi: "Papua",
			kota: [
				"Jayapura",
				"Deiyai",
				"Intan Jaya",
				"Dogiyai",
				"Puncak",
				"Nduga",
				"Lanny Jaya",
				"Yalimo",
				"Mamberamo Tengah",
				"Mamberamo Raya",
				"Supiori",
				"Asmat",
				"Mappi",
				"Boven Digoel",
				"Waropen",
				"Tolikara",
				"Yahukimo",
				"Pegunungan Bintang",
				"Keerom",
				"Sarmi",
				"Mimika",
				"Paniai",
				"Puncak Jaya",
				"Biak Numfor",
				"Kepulauan Yapen",
				"Nabire",
				"Jayapura",
				"Jayawijaya",
				"Merauke",
			],
		},

		{
			provinsi: "Papua Barat",
			kota: [
				"Sorong",
				"Pegunungan Arfak",
				"Manokwari Selatan",
				"Maybrat",
				"Tambrauw",
				"Kaimana",
				"Teluk Wondama",
				"Teluk Bintuni",
				"Raja Ampat",
				"Sorong Selatan",
				"Fak Fak",
				"Manokwari",
				"Sorong",
			],
		},
	],
	payMethods: {
		"e-wallet": {
			"011": ["ID_DANA", "DANA", 0.015],
			"012": ["ID_LINKAJA", "LinkAja", 0.027],
			"013": ["ID_SHOPEEPAY", "ShopeePay", 0.04],
			"014": ["ID_OVO", "OVO", 0.0273],
			// "015": ["ID_JENIUSPAY", "JeniusPay", 0.02],
		},
		qris: {
			"021": ["ID_DANA", "DANA", 0.007],
			"022": ["ID_LINKAJA", "LinkAja", 0.007],
		},
		VA: {
			"031": ["BCA", "BCA", 4000],
			"032": ["BNI", "BNI", 4000],
			"033": ["BRI", "BRI", 4000],
			"034": ["BJB", "BJB", 4000],
			"035": ["BSI", "BSI", 4000],
			// "036": ["BNC", "BNC", 4000],
			"037": ["CIMB", "CIMB", 4000],
			// "038": ["DBS", "DBS", 4000],
			"039": ["MANDIRI", "MANDIRI", 4000],
			"040": ["PERMATA", "PERMATA", 4000],
			"041": ["SAHABAT_SAMPOERNA", "SAMPOERNA", 4000],
		},
	},
	snkBuyer: "<div></div>"
};

module.exports = config;
