/* ===================== KNOWN BUG ===============================
1. Not refrehed / remove data on form after submit (only remove form, but less 1 form with contain data) (Finished)
2. Not reloaded whatsapp quota info after used 
==================================================================*/
import React, { useEffect, useRef, useState } from "react";
import styles from "./styles/PopUpVoucher.module.css";
import styles2 from "./styles/PopUpTicket.module.css";
import PopUp2 from "./PopUp2";
import {
  BiArrowBack,
  BiCalendar,
  BiCard,
  BiCopy,
  BiFilter,
  BiGrid,
  BiInfoCircle,
  BiPlusCircle,
  BiSolidDiscount,
  BiTrash,
} from "react-icons/bi";
import Select from "react-select";
import InputForm from "../components/InputForm";
import InputLabeled from "../components/InputLabeled";
import FieldBox from "../components/FieldBox";
import axios from "axios";
import Loading from "../components/Loading";
import moment from "moment";
import Alert from "../components/Alert";
import InputToogle from "../components/InputToogle";
import PopUp2Factor from "./PopUp2Factor";
import JWT from "expo-jwt";
import { SupportedAlgorithms } from "expo-jwt/dist/types/algorithms";
import DatePicker from "react-multi-date-picker";
import PopUpSeatNumbers from "./PopUpSeatNumbers";
import Button from "../components/Button";
import InputFiles from "../components/InputFiles";

const handleSuccess = (res) => {
  return {
    data: res.data,
    status: res.status,
  };
};

const handleError = (error) => {
  // console.log(error);
  if (error.response === undefined) {
    return {
      data: { data: [error.message] },
      status: 500,
    };
  } else {
    return {
      data: error.response,
      status: error.response.status,
    };
  }
};

const loadOrg = async ({ orgId, token, email }) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      {
        algorithm: SupportedAlgorithms.HS256,
      }
    );
    let res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/org-profile/${orgId}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          "x-api-key": jwtToken,
          "Content-Type": "application/json",
        },
      }
    );
    return handleSuccess(res);
  } catch (error) {
    return handleError(error);
  }
};

const addInv = async ({
  ticket_ids = [],
  names = [],
  mail_targets = [],
  wa_numbers = [],
  invoices = [],
  visit_dates_ins = [],
  seat_numbers_ins = [],
  with_wa,
  orgId,
  eventId,
  token,
  email,
}) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );
    let basic = {
      ticket_ids,
      names,
      mail_targets,
      wa_numbers,
      invoices,
      with_wa,
      visit_dates_ins,
      seat_numbers_ins,
    };
    let formData = new FormData();

    Object.keys(basic).forEach((key) => {
      let value = basic[key];
      if (key === "invoices") {
        for (let i = 0; i < value.length; i++) {
          if (value[i] && value[i] !== null && value[i] !== undefined) {
            formData.append(`${key}[]`, value[i], value[i].name);
            formData.append("file_orders[]", i);
          }
        }
      } else if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          formData.append(
            `${key}[]`,
            !value[i] || value[i] === null || value[i] === undefined
              ? "-"
              : value[i]
          );
        }
      } else if (value && value !== undefined && value !== null) {
        formData.append(key, value);
      }
    });
    console.log(formData);
    let res = await axios.post(
      process.env.REACT_APP_BACKEND_URL +
        `/api/org/${orgId}/event/${eventId}/manage/create-bulk-invitation-v2`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + token,
          "x-api-key": jwtToken,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return handleSuccess(res);
  } catch (error) {
    return handleError(error);
  }
};

const PopUpInv = ({
  isPopActive,
  setPopUpActive = () => {},
  titlePopUp,
  orgId,
  eventId,
  eventData,
  orgDataBasic,
  isLogin,
  token,
  fnSetLogin,
  tickets = [],
}) => {
  // ===== State control =====
  const [numOfForm, setNumOfForm] = useState(1);
  const [alert, setAlert] = useState({
    state: false,
    type: "",
    content: "",
  });
  const [loading, setLoading] = useState(false);
  const [pausedProcess, setPausedProcess] = useState({
    state: false,
    name: "",
    data: null,
  });
  const [isVerified, setVerify] = useState(true);
  const [verifyMsg, setVerifyMsg] = useState(
    "Mohon maaf. Kode OTP bisa didapatkan kembali minimal 2 menit dari permintaan terakhir."
  );
  const [popUpSeatNumber, setPopUpSeats] = useState({
    state: false,
    data: null,
  });
  const [orgData, setOrgData] = useState(orgDataBasic);

  // ======== validation control ==========
  const [blankTicketIds, setBlankTicketIds] = useState([]);
  const [blankVisitDates, setBlankVisitDates] = useState([]);
  const [blankSeats, setBlankSeats] = useState([]);
  const [blankNames, setBlankNames] = useState([]);
  const [blankMails, setBlankMails] = useState([]);
  const [blankWas, setBlankWas] = useState([]);
  const [isSubmitted, setSubmitted] = useState(false);

  // ===== Form data ======
  const name = useRef([]);
  const mail = useRef([]);
  const wa = useRef([]);
  const [selectedTicket, setSelectedTickets] = useState([]);
  const ticketsRef = useRef([]);
  const invoices = useRef([]);
  const [useWa, setUseWa] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const slcDatesRef = useRef([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const slcSeatsRef = useRef([]);

  const setVerified = ({ state, msg }) => {
    setVerify(state);
    if (msg) {
      setVerifyMsg(msg);
    }
  };

  const filterDateSelector = ({ date }) => {
    let props = {};
    let dayMap = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let index = 0;
    let indexDay = 0;
    // search index day of today from available days event, for get time limitation
    do {
      indexDay = eventData.available_days[index]
        ? dayMap.indexOf(eventData.available_days[index].day)
        : -1;
      index++;
    } while (indexDay < new Date().getDay() && index < dayMap.length);

    let limitTimeToday = null;
    let now = new Date();
    if (eventData.available_days[index - 1]) {
      limitTimeToday =
        eventData.available_days[index - 1].max_limit_time.split(":");
      limitTimeToday = new Date(
        new Date().setHours(
          limitTimeToday[0],
          limitTimeToday[1],
          limitTimeToday[2],
          0
        )
      );
    }

    if (
      !eventData.available_days
        .map((avldt) => avldt.day)
        .includes(date.format("ddd")) ||
      new Date(date.format()).setHours(0, 0, 0, 0) <
        new Date().setHours(0, 0, 0, 0) ||
      (limitTimeToday &&
        now.getDate() === parseInt(date.format("D")) &&
        now.getMonth() === parseInt(date.format("M")) - 1 &&
        now.getFullYear() === parseInt(date.format("YYYY")) &&
        now > limitTimeToday)
    ) {
      props.disabled = true;
    }
    return props;
  };

  const handleSelectTicket = (index, value) => {
    setSelectedTickets((prevData) => {
      prevData[index] = value;
      return [...prevData];
    });

    setBlankTicketIds((prevData) => {
      prevData[index] = false;
      return [...prevData];
    });

    setSelectedSeats((prevData) => {
      prevData[index] = null;
      return [...prevData];
    });
  };

  const hanldeSelectDate = (index, date) => {
    setSelectedDates((prevData) => {
      prevData[index] = eventData.available_days
        .map((avldt) => avldt.day)
        .includes(date.format("ddd"))
        ? date
        : null;
      return [...prevData];
    });

    setBlankVisitDates((prevData) => {
      prevData[index] = false;
      return [...prevData];
    });
  };

  const handleSelectSeat = (index, value) => {
    setSelectedSeats((prevData) => {
      prevData[index] = value;
      return [...prevData];
    });

    if (value) {
      setBlankSeats((prevData) => {
        prevData[index] = false;
        return [...prevData];
      });
    }
  };

  const validatePhoneNum = (number) => {
    const regex = /^(\+?\d{1,3})?0?\d{9,13}$/;
    return regex.test(number);
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const basicValidator = ({ numOfForm, useWa }) => {
    if (useWa && (orgData ? orgData.create_inv_quota : 0) < numOfForm) {
      setAlert({
        state: true,
        type: "danger",
        content: "Mohon maaf ! Kuota whatsapp tidak cukup.",
      });
      return false;
    }
    let haveError = false;
    for (let i = 0; i < numOfForm; i++) {
      if (
        !ticketsRef.current[i] ||
        ticketsRef.current[i].getValue().length === 0
      ) {
        haveError = true;
        blankTicketIds[i] = true;
      }
      if (
        eventData.available_days &&
        eventData.available_days.length > 0 &&
        (!slcDatesRef.current[i] ||
          slcDatesRef.current[i].getElementsByTagName("input")[0].value ===
            "" ||
          slcDatesRef.current[i].getElementsByTagName("input")[0].value ===
            " " ||
          slcDatesRef.current[i].getElementsByTagName("input")[0].value == null)
      ) {
        haveError = true;
        blankVisitDates[i] = true;
      }
      if (
        !name.current[i] ||
        name.current[i].value === "" ||
        name.current[i].value === " " ||
        name.current[i].value == null
      ) {
        haveError = true;
        blankNames[i] = true;
      }
      if (
        !mail.current[i] ||
        mail.current[i].value === "" ||
        mail.current[i].value === " " ||
        mail.current[i].value == null ||
        !validateEmail(mail.current[i].value)
      ) {
        haveError = true;
        blankMails[i] = true;
      }
      if (
        useWa &&
        (!wa.current[i] ||
          wa.current[i].value === "" ||
          wa.current[i].value === " " ||
          wa.current[i].value == null ||
          !validatePhoneNum(wa.current[i].value))
      ) {
        haveError = true;
        blankWas[i] = true;
      }
      if (
        ticketsRef.current[i] &&
        ticketsRef.current[i].getValue().length > 0 &&
        tickets.filter(
          (ticket) =>
            ticket.id === ticketsRef.current[i].getValue()[0].value &&
            ticket.seat_number === 1
        ).length > 0 &&
        (!slcSeatsRef.current[i] ||
          slcSeatsRef.current[i].value === "" ||
          slcSeatsRef.current[i].value === " " ||
          slcSeatsRef.current[i].value == null)
      ) {
        haveError = true;
        blankSeats[i] = true;
      }
    }
    setBlankTicketIds([...blankTicketIds]);
    setBlankVisitDates([...blankVisitDates]);
    setBlankNames([...blankNames]);
    setBlankMails([...blankMails]);
    setBlankWas([...blankWas]);
    setBlankSeats([...blankSeats]);
    if (haveError) {
      setAlert({
        state: true,
        type: "danger",
        content: "Semua yang bertanda bintang wajib diisi !!!",
      });
      return false;
    }
    return true;
  };

  const handleSubmit = ({ numOfForm, useWa }) => {
    setLoading(true);
    if (
      basicValidator({
        numOfForm,
        useWa,
      })
    ) {
      let data = {
        names: name.current.filter((f) => f !== null).map((nm) => nm.value),
        mail_targets: mail.current
          .filter((f) => f !== null)
          .map((m) => m.value),
        wa_numbers: useWa
          ? wa.current.filter((f) => f !== null).map((whtas) => whtas.value)
          : null,
        ticket_ids: ticketsRef.current
          .filter((f) => f !== null)
          .map((ticket) => ticket.getValue()[0].value),
        invoices: invoices.current
          .filter((f) => f !== null)
          .map((invoice) =>
            invoice.files.length > 0 ? invoice.files[0] : null
          ),
        visit_dates_ins:
          slcDatesRef.current.length > 0
            ? slcDatesRef.current
                .filter((f) => f !== null)
                .map((slc) =>
                  slc
                    .getElementsByTagName("input")[0]
                    .value.split("/")
                    .join("-")
                )
            : null,
        seat_numbers_ins:
          slcSeatsRef.current.length > 0
            ? slcSeatsRef.current
                .filter((f) => f !== null)
                .map((slc) => (slc ? slc.value : null))
            : null,
        with_wa: useWa,
        orgId: orgId,
        eventId: eventId,
        token: token,
        email: JSON.parse(localStorage.getItem("user-data")).email,
      };

      if (
        data.seat_numbers_ins !== null &&
        data.seat_numbers_ins.length !== data.ticket_ids.length
      ) {
        for (
          let i = data.seat_numbers_ins.length;
          i < data.ticket_ids.length;
          i++
        ) {
          data.seat_numbers_ins.push(null);
        }
      }

      console.log(data);
      addInv(data).then((res) => {
        if (res.status === 201) {
          if (useWa) {
            loadOrg({
              orgId: orgId,
              token: token,
              email: JSON.parse(localStorage.getItem("user-data")).email,
            }).then((res) => {
              if (res.status === 200) {
                setOrgData(res.data.organization);
              }
              setNumOfForm(0);
              setSelectedTickets([]);
              if (document.getElementById("wa-toogle") && useWa) {
                document.getElementById("wa-toogle").click();
              }
              setSelectedDates([]);
              setSelectedSeats([]);
              setTimeout(() => {
                setNumOfForm(1);
              }, 100);
              setPopUpActive(false);
              setLoading(false);
            });
          } else {
            setNumOfForm(0);
            setSelectedTickets([]);
            if (document.getElementById("wa-toogle") && useWa) {
              document.getElementById("wa-toogle").click();
            }
            setSelectedDates([]);
            setSelectedSeats([]);
            setTimeout(() => {
              setNumOfForm(1);
            }, 100);
            setPopUpActive(false);
            setLoading(false);
          }
        } else if (res.status === 401) {
          setPausedProcess({
            state: true,
            name: "add",
            data: null,
          });
          fnSetLogin(false);
          setLoading(false);
        } else if (res.status === 405) {
          setVerified({
            state: false,
            msg:
              res.data.data.message ===
              "Mohon lakukan verifikasi kembali. Kode OTP telah dikirimkan ke email anda"
                ? res.data.data.message
                : null,
          });
          setPausedProcess({
            state: true,
            name: "add",
            data: null,
          });
          setLoading(false);
        } else {
          setAlert({
            state: true,
            type: "danger",
            content:
              res.status === 500
                ? "Data gagal di simpan. Silahlan ulangi lagi"
                : Object.values(res.data.data).reduce((current, acc) => {
                    return (
                      (current === "" ? current : current + ", ") +
                      (Array.isArray(acc) ? acc.join(", ") : acc)
                    );
                  }, ""),
          });
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pausedProcess.state && isLogin && isVerified) {
      handleSubmit({
        numOfForm: numOfForm,
        useWa: useWa,
      });
      if (isVerified) {
        setPausedProcess({
          state: false,
          name: "",
          data: null,
        });
      }
    }
  }, [pausedProcess, isLogin, isVerified]);

  useEffect(() => {
    if (isSubmitted) {
      setSubmitted(false);
      handleSubmit({
        numOfForm: numOfForm,
        useWa: useWa,
      });
    }
  }, [isSubmitted]);

  useEffect(() => {
    setOrgData(orgDataBasic);
  }, [orgDataBasic]);

  useEffect(() => {
    for (let i = 0; i < slcDatesRef.current.length; i++) {
      if (!selectedDates[i] && slcDatesRef.current[i]) {
        slcDatesRef.current[i].getElementsByTagName("input")[0].value = "";
      }
    }
  });

  return (
    <>
      {!isVerified ? (
        <PopUp2Factor
          setLogin={() => {}}
          verifyState={verifyMsg}
          setVerify={setVerified}
          token={token}
        />
      ) : (
        <></>
      )}
      {popUpSeatNumber.state ? (
        <PopUpSeatNumbers
          fnSetActive={() => {
            setPopUpSeats({
              state: false,
              data: null,
            });
          }}
          mode="edit"
          isMulti={false}
          selectedSeatNumbers={popUpSeatNumber.data.activeSeat}
          extDisabledSeats={popUpSeatNumber.data.disableSeats}
          ticket={popUpSeatNumber.data.ticket}
          visitDate={popUpSeatNumber.data.visitDate}
          fnRenewSeatNum={(newList) => {
            handleSelectSeat(
              popUpSeatNumber.data.index,
              newList.length === 0 ? null : newList[0]
            );
          }}
          fnRenewTicketData={() => {}}
          customPriceValue={"0"}
        />
      ) : (
        <></>
      )}

      <div
        style={!isVerified || popUpSeatNumber.state ? { display: "none" } : {}}
      >
        <PopUp2
          width="928px"
          isActive={isPopActive && titlePopUp === "Invitation"}
          setActiveFn={() => {
            setSubmitted(true);
          }}
          closeBtnAbs={{
            title: "Tutup",
            fn: () => {
              setPopUpActive(false);
            },
          }}
          classNames={{
            wrapper: [styles2.PopUpWrapper2],
            modalDialog: [styles2.ModalDialog],
            popUpBox: [styles2.PopUpBox],
            header: [],
            content: [styles2.PopUpContent],
          }}
          closeBtnTitle={"Kirim"}
          titleHeader={
            <div className={styles2.PopUpHeader}>
              <div>
                <h4>Invitation</h4>
              </div>
            </div>
          }
          content={
            <>
              <Alert
                type={alert.type}
                isShow={alert.state}
                setShowFn={() => {
                  setAlert({
                    state: false,
                    type: "",
                    content: "",
                  });
                }}
                message={alert.content}
                closeBtn={true}
                className={[styles.Alert]}
              />
              {loading ? (
                <div style={{ padding: "50px", width: "100%" }}>
                  <Loading />
                </div>
              ) : (
                <></>
              )}

              <div
                className={styles.PopUpContent}
                style={{ display: loading ? "none" : "flex" }}
              >
                <div
                  className={styles2.Info2}
                  style={{
                    marginLeft: "0px",
                    marginRight: "0px",
                  }}
                >
                  <div className={styles2.CmdField}>
                    <BiInfoCircle />
                  </div>

                  <p>
                    Undangan akan langsung ditambahkan menjadi peserta dan
                    mendapatkan tiket. Sistem Agendakota mengirimkan tiket
                    melalui Email dan WhatsApp Notification (jika diaktifkan).
                  </p>
                </div>
                <div className={`${styles2.TicketEditorContainer}`}>
                  <div className={styles2.EditorRight}>
                    <div className={styles2.TicketSettingContainer}>
                      <div className={styles2.SettingLabel}>
                        <label htmlFor="single-trx">
                          Kirim undangan via WA
                        </label>
                        <p>
                          * link tiket akan dikirimkan melalui WhatsApp
                          dikenakan charge Rp 2000 / undangan
                        </p>
                        <p>
                          * Hubungi / klik contact person berikut untuk request
                          pengisian kuota / penggunaan whatsapp invitation
                        </p>
                        <a href="https://wa.me/6288990079999">
                          Cp. Agendakota (6288990079999)
                        </a>
                      </div>
                      <div className={styles2.SettingField}>
                        <InputToogle
                          id={"wa-toogle"}
                          onChange={() => {
                            setUseWa(!useWa);
                          }}
                        />
                      </div>
                    </div>
                    {useWa ? (
                      <div
                        className={styles2.Info2}
                        style={{
                          marginLeft: "0px",
                          marginRight: "0px",
                          backgroundColor: "#ffff008a",
                        }}
                      >
                        <div
                          className={styles2.CmdField}
                          style={{ color: "black" }}
                        >
                          <BiInfoCircle />
                        </div>

                        <p style={{ color: "black" }}>
                          <p>
                            Kuota whatsapp invitation anda tersisa{" "}
                            {orgData ? orgData.create_inv_quota : 0}
                          </p>
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      style={{
                        backgroundColor: "#F1F1F1",
                        color: "#8B8B8B",
                        padding: "12px",
                        width: "100%",
                        marginBottom: "25px",
                        fontSize: "14px",
                      }}
                    >
                      Undang partner Anda untuk datang pada event Anda.
                    </div>

                    {Array(numOfForm)
                      .fill(null)
                      .map((_, index) => {
                        return (
                          <>
                            <div
                              className={`${styles.ClassicForm} ${
                                blankTicketIds[index]
                                  ? styles2.DangerInput2
                                  : ""
                              }`}
                            >
                              <label htmlFor="">Pilih Tiket *</label>
                              <Select
                                ref={(el) => {
                                  ticketsRef.current[index] = el;
                                }}
                                onChange={(e) => handleSelectTicket(index, e)}
                                required
                                options={tickets.map((ticket) => ({
                                  label: ticket.name,
                                  value: ticket.id,
                                }))}
                                styles={{
                                  option: (basicStyle, state) => ({
                                    ...basicStyle,
                                    backgroundColor: state.isFocused
                                      ? "#fecadf"
                                      : "white",
                                    fontSize: "13px",
                                    width: "100%",
                                  }),
                                  control: (basicStyle, state) => ({
                                    ...basicStyle,
                                    display: "flex",
                                    flexDirection: "row",
                                    "&:hover": { borderColor: "#fecadf" },
                                    border: "1px solid #eae7e7",
                                    borderRadius: "8px",
                                    height:
                                      state.getValue().length === 0
                                        ? "41px"
                                        : "91px",
                                    fontSize: "13px",
                                    boxShadow: "none",
                                    width: "100%",
                                  }),
                                  container: (basicStyle, state) => ({
                                    ...basicStyle,
                                    width: "80%",
                                  }),
                                }}
                              />
                            </div>

                            {eventData.available_days &&
                            eventData.available_days.length > 0 ? (
                              <div
                                className={`${styles.ClassicForm} ${
                                  blankVisitDates[index]
                                    ? styles2.DangerInput2
                                    : ""
                                }`}
                              >
                                {console.log(
                                  selectedDates[index]
                                    ? selectedDates[index].format()
                                    : "blank"
                                )}
                                <label htmlFor="">Tanggal Kunjungan *</label>
                                <DatePicker
                                  ref={(el) => {
                                    slcDatesRef.current[index] = el;
                                  }}
                                  required
                                  containerStyle={{
                                    width: "calc(80% - 34px)",
                                    fontSize: "12px",
                                  }}
                                  inputClass={`${styles.CustomInputDate}`}
                                  mapDays={({ date }) =>
                                    filterDateSelector({ date: date })
                                  }
                                  value={
                                    selectedDates[index]
                                      ? selectedDates[index]
                                      : null
                                  }
                                  onChange={(e) => hanldeSelectDate(index, e)}
                                  placeholder="Pilih tanggal kunjungan"
                                  style={{
                                    border: "none",
                                    width: "100%",
                                  }}
                                  multiple={false}
                                  id={`dt-picker-cart-${index}`}
                                  onClose={() => {
                                    setTimeout(() => {
                                      if (selectedDates[index] == null) {
                                        document.getElementById(
                                          `dt-picker-cart-${index}`
                                        ).value = "";
                                      }
                                    }, 100);
                                  }}
                                />
                              </div>
                            ) : (
                              <></>
                            )}

                            {selectedTicket.length > 0 &&
                            selectedTicket[index] &&
                            tickets.filter(
                              (ticket) =>
                                ticket.id === selectedTicket[index].value &&
                                ticket.seat_number === 1
                            ).length > 0 ? (
                              <div
                                className={`${styles.ClassicForm} ${
                                  blankSeats[index] ? styles2.DangerInput2 : ""
                                }`}
                              >
                                <label htmlFor="">Tempat Duduk *</label>
                                <input
                                  type="hidden"
                                  ref={(el) => {
                                    slcSeatsRef.current[index] = el;
                                  }}
                                  value={selectedSeats[index]}
                                />
                                {!selectedSeats[index] ? (
                                  <Button
                                    title={"Pilih Tempat Duduk"}
                                    style={{ width: "unset" }}
                                    fnOnClick={() => {
                                      if (
                                        eventData.available_days &&
                                        eventData.available_days.length > 0 &&
                                        !selectedDates[index]
                                      ) {
                                        setAlert({
                                          state: true,
                                          type: "danger",
                                          content:
                                            "Silahkan isi tanggal kunjungan dahulu",
                                        });
                                        blankVisitDates[index] = true;
                                        setBlankVisitDates([
                                          ...blankVisitDates,
                                        ]);
                                      } else {
                                        setPopUpSeats({
                                          state: true,
                                          data: {
                                            index: index,
                                            activeSeat: [selectedSeats[index]],
                                            disableSeats: selectedSeats.filter(
                                              (slc, index2) => {
                                                return (
                                                  index2 ===
                                                    (selectedTicket[index2]
                                                      .value ===
                                                      selectedTicket[index]
                                                        .value &&
                                                    (selectedDates[index2]
                                                      ? selectedDates[
                                                          index
                                                        ].format() ===
                                                        selectedDates[
                                                          index2
                                                        ].format()
                                                      : true)
                                                      ? index2
                                                      : null) &&
                                                  index2 !== index
                                                );
                                              }
                                            ),
                                            ticket: tickets.filter(
                                              (ticket) =>
                                                ticket.id ===
                                                selectedTicket[index].value
                                            )[0],
                                            visitDate: selectedDates[index],
                                          },
                                        });
                                      }
                                    }}
                                  />
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        marginRight: "auto",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      {selectedSeats[index]}
                                    </div>
                                    <Button
                                      title={"Ganti Tempat Duduk"}
                                      style={{ width: "unset" }}
                                      fnOnClick={() => {
                                        if (
                                          eventData.available_days &&
                                          eventData.available_days.length > 0 &&
                                          !selectedDates[index]
                                        ) {
                                          setAlert({
                                            state: true,
                                            type: "danger",
                                            content:
                                              "Silahkan isi tanggal kunjungan dahulu",
                                          });
                                          blankVisitDates[index] = true;
                                          setBlankVisitDates([
                                            ...blankVisitDates,
                                          ]);
                                        } else {
                                          setPopUpSeats({
                                            state: true,
                                            data: {
                                              index: index,
                                              activeSeat: [
                                                selectedSeats[index],
                                              ],
                                              disableSeats:
                                                selectedSeats.filter(
                                                  (slc, index2) => {
                                                    return (
                                                      index2 ===
                                                        (selectedTicket[index2]
                                                          .value ===
                                                          selectedTicket[index]
                                                            .value &&
                                                        (selectedDates[index2]
                                                          ? selectedDates[
                                                              index
                                                            ].format() ===
                                                            selectedDates[
                                                              index2
                                                            ].format()
                                                          : true)
                                                          ? index2
                                                          : null) &&
                                                      index2 !== index
                                                    );
                                                  }
                                                ),
                                              ticket: tickets.filter(
                                                (ticket) =>
                                                  ticket.id ===
                                                  selectedTicket[index].value
                                              )[0],
                                              visitDate: selectedDates[index],
                                            },
                                          });
                                        }
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                            <div
                              className={`${styles.ClassicForm} ${
                                blankNames[index] ? styles2.DangerInput2 : ""
                              }`}
                            >
                              <label htmlFor="">Nama *</label>
                              <InputForm
                                required
                                type={"text"}
                                placeholder={"Nama partner"}
                                refData={(el) => (name.current[index] = el)}
                                onChange={(e) => {
                                  setBlankNames((prevData) => {
                                    prevData[index] = false;
                                    return [...prevData];
                                  });
                                }}
                              />
                            </div>
                            <div
                              className={`${styles.ClassicForm} ${
                                blankMails[index] ? styles2.DangerInput2 : ""
                              }`}
                            >
                              <label htmlFor="">Email *</label>
                              <InputForm
                                required
                                type={"text"}
                                placeholder={"Nama partner"}
                                refData={(el) => (mail.current[index] = el)}
                                onChange={(e) => {
                                  setBlankMails((prevData) => {
                                    prevData[index] = false;
                                    return [...prevData];
                                  });
                                }}
                              />
                            </div>
                            {useWa ? (
                              <div
                                className={`${styles.ClassicForm} ${
                                  blankWas[index] ? styles2.DangerInput2 : ""
                                }`}
                              >
                                <label htmlFor="">No. WhatsApp *</label>
                                <InputForm
                                  required
                                  type={"text"}
                                  placeholder={"Nama partner"}
                                  refData={(el) => (wa.current[index] = el)}
                                  onChange={(e) => {
                                    setBlankWas((prevData) => {
                                      prevData[index] = false;
                                      return [...prevData];
                                    });
                                  }}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className={`${styles.ClassicForm}`}>
                              <label htmlFor="">
                                Invoice /Lampiran (Opsional)
                              </label>
                              <InputFiles
                                placeholder={"Nama partner"}
                                refData={(el) => (invoices.current[index] = el)}
                                accept={["image/png", "image/jpeg", ".pdf"]}
                              />
                            </div>
                            {numOfForm > 1 && index < numOfForm - 1 ? (
                              <hr
                                style={{
                                  marginTop: "15px",
                                  marginBottom: "15px",
                                  border: "1px dashed #ca0c64",
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}
                  </div>
                </div>
                <div className={styles.PopUpContentNav}>
                  <div
                    className={styles.NavButton}
                    style={{ cursor: "pointer", marginTop: "25px" }}
                    onClick={() => {
                      setNumOfForm(numOfForm + 1);
                    }}
                  >
                    <BiPlusCircle />
                    <div>Tambah Invitation</div>
                  </div>
                </div>
              </div>
            </>
          }
        />
      </div>
    </>
  );
};

export default PopUpInv;
