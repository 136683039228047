import React, { useEffect, useRef, useState } from "react";
import styles from "./styles/PopUpVoucher.module.css";
import styles2 from "./styles/PopUpTicket.module.css";
import PopUp2 from "./PopUp2";
import {
  BiArrowBack,
  BiCalendar,
  BiCard,
  BiCopy,
  BiDownload,
  BiFilter,
  BiGrid,
  BiInfoCircle,
  BiPlusCircle,
  BiSolidDiscount,
  BiTrash,
} from "react-icons/bi";
import Select from "react-select";
import InputForm from "../components/InputForm";
import InputLabeled from "../components/InputLabeled";
import FieldBox from "../components/FieldBox";
import axios from "axios";
import Loading from "../components/Loading";
import moment from "moment";
import Alert from "../components/Alert";
import InputToogle from "../components/InputToogle";
import PopUp2Factor from "./PopUp2Factor";
import JWT from "expo-jwt";
import { SupportedAlgorithms } from "expo-jwt/dist/types/algorithms";
import Button from "../components/Button";
import InputFiles from "../components/InputFiles";

const handleSuccess = (res) => {
  return {
    data: res.data,
    status: res.status,
  };
};

const handleError = (error) => {
  // console.log(error);
  if (error.response === undefined) {
    return {
      data: { data: [error.message] },
      status: 500,
    };
  } else {
    return {
      data: error.response,
      status: error.response.status,
    };
  }
};

const loadOrg = async ({ orgId, token, email }) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      {
        algorithm: SupportedAlgorithms.HS256,
      }
    );
    let res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/org-profile/${orgId}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          "x-api-key": jwtToken,
          "Content-Type": "application/json",
        },
      }
    );
    return handleSuccess(res);
  } catch (error) {
    return handleError(error);
  }
};

const addInv = async ({
  xlsx,
  invoices = [],
  with_wa,
  orgId,
  eventId,
  token,
  email,
}) => {
  try {
    let jwtToken = JWT.encode(
      {
        client_id: process.env.REACT_APP_ID,
        email_user: email,
        timestamp: new Date().getTime(),
      },
      process.env.REACT_APP_BACKEND_KEY,
      { algorithm: SupportedAlgorithms.HS256 }
    );

    let formData = new FormData();

    formData.append(`spread_file`, xlsx, xlsx.name);
    for (let i = 0; i < invoices.length; i++) {
      formData.append("invoice_datas[]", invoices[i], invoices[i].name);
    }
    if (with_wa) {
      formData.append("with_wa", with_wa);
    }

    let res = await axios.post(
      process.env.REACT_APP_BACKEND_URL +
        `/api/org/${orgId}/event/${eventId}/manage/create-bulk-invitation`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + token,
          "x-api-key": jwtToken,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return handleSuccess(res);
  } catch (error) {
    return handleError(error);
  }
};

const PopUpInv2 = ({
  isPopActive,
  setPopUpActive = () => {},
  titlePopUp,
  orgId,
  eventId,
  orgDataBasic,
  isLogin,
  token,
  fnSetLogin,
}) => {
  // ===== State control =====
  const [numOfForm, setNumOfForm] = useState(1);
  const [alert, setAlert] = useState({
    state: false,
    type: "",
    content: "",
  });
  const [loading, setLoading] = useState(false);
  const [pausedProcess, setPausedProcess] = useState({
    state: false,
    name: "",
    data: null,
  });
  const [isVerified, setVerify] = useState(true);
  const [verifyMsg, setVerifyMsg] = useState(
    "Mohon maaf. Kode OTP bisa didapatkan kembali minimal 2 menit dari permintaan terakhir."
  );
  const [orgData, setOrgData] = useState(orgDataBasic);
  const [blankXls, setBlankXls] = useState(false);
  const [blankInvoices, setBlankInvoices] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  // ===== Form data ======
  const invoices = useRef();
  const xlsData = useRef();
  const [useWa, setUseWa] = useState(false);

  const setVerified = ({ state, msg }) => {
    setVerify(state);
    if (msg) {
      setVerifyMsg(msg);
    }
  };

  const basicValidator = () => {
    if (
      !xlsData.current ||
      xlsData.current.files.length === 0 ||
      (xlsData.current.files[0].type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        xlsData.current.files[0].type !== "application/vnd.ms-excel")
    ) {
      setAlert({
        state: true,
        type: "danger",
        content:
          xlsData.current &&
          xlsData.current.files > 0 &&
          xlsData.current.files[0].type !==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
          xlsData.current.files[0].type !== "application/vnd.ms-excel"
            ? "Sistem hanya menerima file berformat XLS dan XLSX"
            : "File XLS wajib diinputkan",
      });
      setBlankXls(true);
      return false;
    } else if (invoices.current && invoices.current.files.length > 0) {
      for (let i = 0; i < invoices.current.files.length; i++) {
        if (
          invoices.current.files[i].type !== "image/png" &&
          invoices.current.files[i].type !== "image/jpeg" &&
          invoices.current.files[i].type !== "application/pdf"
        ) {
          setAlert({
            state: true,
            type: "danger",
            content:
              "Invoice / Lampiran hanya menerima file berformat png, jpg, dan pdf",
          });
          setBlankInvoices(true);
          return false;
        }
      }
    }
    return true;
  };

  const handleSubmit = ({ useWa }) => {
    setLoading(true);
    if (basicValidator()) {
      let data = {
        xlsx: xlsData.current.files[0],
        invoices:
          invoices.current && invoices.current.files.length > 0
            ? invoices.current.files
            : [],
        with_wa: useWa,
        orgId: orgId,
        eventId: eventId,
        token: token,
        email: JSON.parse(localStorage.getItem("user-data")).email,
      };

      console.log(data);

      addInv(data).then((res) => {
        if (res.status === 201) {
          if (useWa) {
            loadOrg({
              orgId: orgId,
              token: token,
              email: JSON.parse(localStorage.getItem("user-data")).email,
            }).then((res) => {
              if (res.status === 200) {
                setOrgData(res.data.organization);
              }
              if (document.getElementById("wa-toogle") && useWa) {
                document.getElementById("wa-toogle").click();
              }
              xlsData.current.value = "";
              invoices.current.value = "";
              setPopUpActive(false);
              setLoading(false);
            });
          } else {
            if (document.getElementById("wa-toogle") && useWa) {
              document.getElementById("wa-toogle").click();
            }
            xlsData.current.value = "";
            invoices.current.value = "";
            setPopUpActive(false);
            setLoading(false);
          }
        } else if (res.status === 401) {
          setPausedProcess({
            state: true,
            name: "add",
            data: null,
          });
          fnSetLogin(false);
          setLoading(false);
        } else if (res.status === 405) {
          setVerified({
            state: false,
            msg:
              res.data.data.message ===
              "Mohon lakukan verifikasi kembali. Kode OTP telah dikirimkan ke email anda"
                ? res.data.data.message
                : null,
          });
          setPausedProcess({
            state: true,
            name: "add",
            data: null,
          });
          setLoading(false);
        } else {
          setAlert({
            state: true,
            type: "danger",
            content:
              res.status === 500
                ? "Data gagal di simpan. Silahlan ulangi lagi"
                : Object.values(res.data.data).reduce((current, acc) => {
                    return (
                      (current === "" ? current : current + ", ") +
                      (Array.isArray(acc) ? acc.join(", ") : acc)
                    );
                  }, ""),
          });
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pausedProcess.state && isLogin && isVerified) {
      handleSubmit({
        useWa: useWa,
      });
      if (isVerified) {
        setPausedProcess({
          state: false,
          name: "",
          data: null,
        });
      }
    }
  }, [pausedProcess, isLogin, isVerified]);

  useEffect(() => {
    setOrgData(orgDataBasic);
  }, [orgDataBasic]);

  useEffect(() => {
    if (isSubmitted) {
      handleSubmit({
        useWa: useWa,
      });
      setSubmitted(false);
    }
  }, [isSubmitted]);

  return (
    <>
      {!isVerified ? (
        <PopUp2Factor
          setLogin={() => {}}
          verifyState={verifyMsg}
          setVerify={setVerified}
          token={token}
        />
      ) : (
        <></>
      )}
      <div style={!isVerified ? { display: "none" } : {}}>
        <PopUp2
          width="928px"
          isActive={isPopActive && titlePopUp === "bulk_inv"}
          setActiveFn={() => {
            setSubmitted(true);
          }}
          closeBtnAbs={{
            title: "Tutup",
            fn: () => {
              setPopUpActive(false);
            },
          }}
          classNames={{
            wrapper: [styles2.PopUpWrapper2],
            modalDialog: [styles2.ModalDialog],
            popUpBox: [styles2.PopUpBox],
            header: [],
            content: [styles2.PopUpContent],
          }}
          closeBtnTitle={"Kirim"}
          titleHeader={
            <div className={styles2.PopUpHeader}>
              <div>
                <h4>Invitation</h4>
              </div>
            </div>
          }
          content={
            <>
              <Alert
                type={alert.type}
                isShow={alert.state}
                setShowFn={() => {
                  setAlert({
                    state: false,
                    type: "",
                    content: "",
                  });
                }}
                message={alert.content}
                closeBtn={true}
                className={[styles.Alert]}
              />
              {loading ? (
                <div style={{ padding: "50px", width: "100%" }}>
                  <Loading />
                </div>
              ) : (
                <></>
              )}
              <div
                className={styles.PopUpContent}
                style={{ display: loading ? "none" : "flex" }}
              >
                <div
                  className={styles2.Info2}
                  style={{
                    marginLeft: "0px",
                    marginRight: "0px",
                  }}
                >
                  <div className={styles2.CmdField}>
                    <BiInfoCircle />
                  </div>
                  <p>
                    Undangan akan langsung ditambahkan menjadi peserta dan
                    mendapatkan tiket. Sistem Agendakota mengirimkan tiket
                    melalui Email dan WhatsApp Notification (jika diaktifkan).
                  </p>
                </div>
                <div
                  className={styles2.Info2}
                  style={{
                    marginLeft: "0px",
                    marginRight: "0px",
                    marginBottom: 0,
                    paddingBottom: "25px",
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                >
                  <div className={styles2.CmdField}>
                    <BiInfoCircle />
                  </div>
                  <p>
                    Form berikut ini menerima file XLS atau Excel dari data
                    undangan yang akan dikirimkan. Klik timbol di bawah ini
                    untuk mendownload template file XLS dari batch invitation.
                  </p>
                </div>
                <div
                  className={styles2.Info2}
                  style={{
                    marginLeft: "0px",
                    marginRight: "0px",
                    marginTop: "-15px",
                    paddingTop: 0,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    backgroundColor: "rgb(207 207 207)",
                  }}
                >
                  <div className={styles2.CmdField}>Download :</div>
                  <Button
                    icon={<BiDownload />}
                    title={"Template"}
                    style={{ width: "unset", marginTop: "15px" }}
                    fnOnClick={() => {
                      window.open(process.env.REACT_APP_XLS_TEMPLATE, "_blank");
                    }}
                  />
                </div>

                <div className={`${styles2.TicketEditorContainer}`}>
                  <div className={styles2.EditorRight}>
                    <div className={styles2.TicketSettingContainer}>
                      <div className={styles2.SettingLabel}>
                        <label htmlFor="single-trx">
                          Kirim undangan via WA
                        </label>
                        <p>
                          * link tiket akan dikirimkan melalui WhatsApp
                          dikenakan charge Rp 2000 / undangan
                        </p>
                        <p>
                          * Hubungi / klik contact person berikut untuk request
                          pengisian kuota / penggunaan whatsapp invitation
                        </p>
                        <a href="https://wa.me/6288990079999">
                          Cp. Agendakota (6288990079999)
                        </a>
                      </div>
                      <div className={styles2.SettingField}>
                        <InputToogle
                          id={"wa-toogle"}
                          onChange={() => {
                            setUseWa(!useWa);
                          }}
                        />
                      </div>
                    </div>
                    {useWa ? (
                      <div
                        className={styles2.Info2}
                        style={{
                          marginLeft: "0px",
                          marginRight: "0px",
                          backgroundColor: "#ffff008a",
                        }}
                      >
                        <div
                          className={styles2.CmdField}
                          style={{ color: "black" }}
                        >
                          <BiInfoCircle />
                        </div>

                        <p style={{ color: "black" }}>
                          <p>
                            Kuota whatsapp invitation anda tersisa{" "}
                            {orgData ? orgData.create_inv_quota : 0}
                          </p>
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      style={{
                        backgroundColor: "#F1F1F1",
                        color: "#8B8B8B",
                        padding: "12px",
                        width: "100%",
                        marginBottom: "25px",
                        fontSize: "14px",
                      }}
                    >
                      Undang partner Anda untuk datang pada event Anda.
                    </div>
                    <div
                      className={`${styles.ClassicForm} ${
                        blankXls ? styles2.DangerInput2 : ""
                      }`}
                    >
                      <label htmlFor="">Data XLS Undangan *</label>
                      <InputFiles
                        placeholder={"XLS Data"}
                        refData={xlsData}
                        accept={[
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          "application/vnd.ms-excel",
                        ]}
                        onChange={() => {
                          setBlankXls(false);
                        }}
                      />
                    </div>
                    <div
                      className={`${styles.ClassicForm} ${
                        blankInvoices ? styles2.DangerInput2 : ""
                      }`}
                    >
                      <label htmlFor="">Invoice / Lampiran (Opsional)</label>
                      <InputFiles
                        multiple={true}
                        accept={["image/png", "image/jpeg", ".pdf"]}
                        refData={invoices}
                        onChange={() => {
                          setBlankInvoices(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        />
      </div>
    </>
  );
};

export default PopUpInv2;
